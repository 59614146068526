<template>
  <div class="container">
    <div class="m-0 row vh-100 justify-content-center align-items-center">
      <div class="col-lg-4 col-md-8 col-sm-10">
        <div class="card" style="opacity: 0.8">
          <div class="text-center mt-3">
            <img
              class="img-fluid img-responsive"
              width="65%"
              height="65%"
              src="../assets/logo_deportes.jpeg"
              alt="Logo"
            />
          </div>
          <div class="card-body">
            <h4 class="text-center"><i :class="icon"></i> {{ title }}</h4>
            <div class="text-center mt-3">
              <small class="text-help">{{ author }} @ {{ gestion }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loading v-show="cargando" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Loading from "@/components/Loading";

export default {
  components: {
    Loading,
  },
  props: {
    state: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      cargando: false,
    };
  },
  methods: {
    ...mapActions(["guardarUsuario"]),
    async loginByState(state) {
      this.cargando = true;

      try {
        this.guardarUsuario(state);
        window.location.href = "/";
      } catch (error) {
        console.error("Error al procesar el login:", error);
      } finally {
        this.cargando = false;
      }
    },
  },
  created() {
    this.title = process.env.VUE_APP_TITLE;
    this.author = process.env.VUE_APP_AUTHOR;
    this.icon = process.env.VUE_APP_ICON;
    this.gestion = new Date().getFullYear();
  },
  mounted() {
    if (this.state) {
      const decodedState = atob(this.state);
      console.log(decodedState);
      this.loginByState(decodedState);
    } else {
      console.error("State no encontrado en las props");
    }
  },
};
</script>
